import { useFormik } from 'formik';
import { TextField, Button } from '@material-ui/core'
import './login.scss';
import { RestApi } from '../../utils/apirequester';
import { useHistory } from 'react-router-dom';
const Login = () => {
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },

        onSubmit: async (values, e) => {
            try {
                
                const res = await RestApi.post<any>('/api/v1/auth/login', values);
                if(typeof res.token !== 'string') {
                    return;
                }
                RestApi.setToken(res.token);
                history.replace('/dashboard');
            } catch (error) {
                
            }
        },
    });
    return (
        <form className="Login" onSubmit={formik.handleSubmit}>

            <TextField
                name="username"
                label="Kullanıcı Adı"
                onChange={formik.handleChange}
                value={formik.values.username}
            />
            <TextField
                name="password"
                type="password"
                label="Şifre"
                onChange={formik.handleChange}
                value={formik.values.password}

            />
            <Button type="submit">Giriş Yap</Button>
        </form>
    )
}
export default Login;