import axios from 'axios';
function setToken(token: string) {
    window.localStorage.setItem('TOKEN', token);
}
function removeToken() {
    window.localStorage.removeItem('TOKEN');
}
function getToken() {
    return window.localStorage.getItem('TOKEN');
}
const getBaseUrl = () => {
    if (process.env.REACT_APP_API_URL) {
        return process.env.REACT_APP_API_URL;
    }
    return '';
}
const getHeaders = (defaultHeader?: any) => {
    const token = getToken();
    const baseHeader = token ? {
        Authorization: 'Bearer ' + token,
    } : {};
    if (defaultHeader) {
        return Object.assign(defaultHeader, baseHeader);
    }
    return baseHeader;
}
const defaultGet = <T>(url: string): Promise<T> => {
    return fetch(getBaseUrl() + url, {
        headers: getHeaders(),
    }).then(a => {
        if (!a.ok) {
            throw new Error('NOT FOUND ERROR')
        }
        return a.json();
    })
}
const defaultGetRaw = (url: string) => {
    return fetch(getBaseUrl() + url, {
        headers: getHeaders()
    })
}
const defaultDelete = <T>(url: string): Promise<T> => {
    return fetch(getBaseUrl() + url, {
        headers: getHeaders(),
        method: 'DELETE'
    }).then(a => a.json())
}
const defaultPost = <T>(url: string, body: any): Promise<T> => {
    return fetch(getBaseUrl() + url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: getHeaders({
            'content-type': 'application/json',
        })
    })
        .then(a => a.json())
}
const defaultPut = <T>(url: string, body: any): Promise<T> => {
    return fetch(getBaseUrl() + url, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: getHeaders({
            'content-type': 'application/json',
        })
    })
        .then(a => a.json())
}
const multiPartPost = <T>(url: string, body: FormData): Promise<T> => {
    return fetch(getBaseUrl() + url, {
        method: 'POST',
        body,
        headers: getHeaders()
    }).then(a => a.json())
}
const multiPartPostV2 = <T>(url: string, body: FormData, onUploadProgress: (e: ProgressEvent) => void): Promise<T> => {
    return axios.post(getBaseUrl() + url, body, {
        headers: getHeaders(),
        onUploadProgress
    })
}

export const RestApi = {
    get: defaultGet,
    getRaw: defaultGetRaw,
    post: defaultPost,
    put: defaultPut,
    multiPartPost,
    multiPartPostV2,
    delete: defaultDelete,
    setToken,
    removeToken,
    getToken,
    getBaseUrl,
};
