import { Button, FormControl, TextField } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { RestApi } from '../../../../utils/apirequester';
import { DataCesium3DTileset } from '../../../../utils/models';
import { getStateElement } from '../../../../utils/util';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import Prompt from '../../../../components/prompt/Prompt';
import DeleteIcon from '@material-ui/icons/Delete';
import './Cesium3DTilesetEdit.scss';
import { UploaderContext } from '../uploader/Uploader';
import { startDirectorySearch } from '../uploader/OpenFolderPicker';


const Cesium3DTilesetEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Çok Kısa')
        .max(50, 'Çok Uzun')
        .required('Zorunlu')
})

interface Props {
    reloadCallback: () => void;
}
export default function Cesium3DTilesetEdit(props: Props) {

    const uploaderContext = useContext(UploaderContext);
    
    const { id } = useParams<any>();
    const [cesiumt3DTileset, setCesiumt3DTileset] = useState<DataCesium3DTileset | undefined>(undefined);
    const [deleteForm, setDeleteForm] = useState(false);
    const [deleteDataForm, setDeleteDataForm] = useState(false);
    const history = useHistory();

    const formik = useFormik({
        initialValues: { name: '' },
        validationSchema: Cesium3DTilesetEditSchema,
        onSubmit: (values) => {
            RestApi.put<DataCesium3DTileset>('/api/v1/cesium3dtileset/' + id, values)
                .then(res => {
                    reloadloadData();
                    props.reloadCallback();
                });
        }
    });
    useEffect(() => { reloadloadData(); }, [id]);
    async function reloadloadData() {
        const res = await RestApi.get<DataCesium3DTileset>('/api/v1/cesium3dtileset/' + id)
        formik.setValues(res);
        setCesiumt3DTileset(res);
    }
    async function startVerify() {
        await RestApi.get<any>(`/api/v1/cesium3dtileset/${id}/verify`);
        await reloadloadData();
    }
    async function deleteCesium3DTileset() {
        await RestApi.delete<any>(`/api/v1/cesium3dtileset/${id}`);
        history.push('/dashboard/cesium3dtileset');
        props.reloadCallback();
    }
    async function deleteOnlyCesium3DTileset() {
        await RestApi.delete<any>(`/api/v1/cesium3dtileset/${id}/dataonly`);
        await reloadloadData();
    }
    async function onVeriUpload() {
        try {
            const files = await startDirectorySearch();
            uploaderContext({
                tileset_id: id,
                uploadPath: `/api/v1/cesium3dtileset/${id}/uploadfile`,
                files,
                infoCallback: startVerify,
                title: cesiumt3DTileset?.name || '',
            });
        } catch (error) {
            console.log('ERROR', error);
            alert(error.message);
        }
    }


    if (!cesiumt3DTileset) {
        return null;
    }
    return (
        <div className="Cesium3DTilesetEdit">
            <div className="header">
                <h3>{cesiumt3DTileset.name}</h3>
                <Button onClick={() => setDeleteForm(true)}><DeleteIcon /></Button>
            </div>
            <div className="data">
                <Button onClick={onVeriUpload}>Veri Yükle</Button>
                <Button color="primary" onClick={startVerify}>Veri Kontrol Et</Button>
                <Button color="secondary" onClick={() => setDeleteDataForm(true)}>Veri Sil</Button>
                {getStateElement(cesiumt3DTileset.state)}
            </div>
            <form onSubmit={formik.handleSubmit}>
                <h3>Güncelleme</h3>
                <FormControl>
                    <TextField
                        id="name"
                        label="Cesium3DTileset Adı"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={formik.touched.name && typeof formik.errors.name === 'string'}
                    />
                </FormControl>

                <Button type="submit">Güncelle</Button>
            </form>
            <Prompt
                open={deleteForm}
                callback={(res) => {
                    setDeleteForm(false);
                    if (res) {
                        deleteCesium3DTileset();
                        return;
                    }
                }}
                title="Cesium3DTileset Siliniyor"
                question="Dikkat Cesium3DTileset siliniyor. Bu Cesium3DTileset herhangi bir projede kullanılıyor ise o projeden de bu Cesium3DTileset Silinecektir"
                agreeText="Sil"
                disAgreeText="İptal"
            />
            <Prompt
                open={deleteDataForm}
                callback={(res) => {
                    setDeleteDataForm(false);
                    if (res) {
                        deleteOnlyCesium3DTileset();
                        return;
                    }
                }}
                title="Cesium3DTileset Verileri"
                question="Dikkat Cesium3DTileset Verileri siliniyor. tileset.json gibi dosyaları tekrardan yüklemeniz gerekir"
                agreeText="Sil"
                disAgreeText="İptal"
            />
        </div>
    )
}
