import React, {
    useState,
    useEffect,
    useContext,
    PropsWithChildren
} from 'react'
import { RestApi } from '../../../utils/apirequester';
import { CesiumViewerContext } from '../cesiumviewer/CesiumViewer';
import { CustomGeojsonDataStore } from './GeojsonDataStore';
interface Props {
    style: string | null;
    geojsonId: number;
    classification: boolean;
    visible: boolean;
}
export default function GeojsonLoader(props: PropsWithChildren<Props>) {
    const viewer = useContext(CesiumViewerContext);
    const [customGeojsonDataSource, setCustomGeojsonDataSource] = useState<CustomGeojsonDataStore | null>(null);
    const url = RestApi.getBaseUrl() + `/api/v1/static/geojson/${props.geojsonId}.json`;
    useEffect(() => {
        if (!viewer) {
            return;
        }
        if (customGeojsonDataSource) {
            return;
        }
        const cgds = new CustomGeojsonDataStore(viewer,
            url,
            props.visible,
            props.classification,
            props.style
        );
        setCustomGeojsonDataSource(cgds);
        return
    }, [viewer]);

    useEffect(() => {
        updateClassification();
    }, [props.classification])
    useEffect(() => {
        updateVisible();
    }, [props.visible])
    useEffect(() => {
        updateStyle();
    }, [props.style])

    function updateClassification() {
        if (!customGeojsonDataSource) {
            return;
        }
        customGeojsonDataSource.setClassification(props.classification);
    }
    function updateVisible() {
        if (!customGeojsonDataSource) {
            return;
        }
        customGeojsonDataSource.setVisible(props.visible);
    }
    function updateStyle() {
        if (!customGeojsonDataSource) {
            return;
        }
        customGeojsonDataSource.setColor(props.style);
    }

    return null;
}
