import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { RestApi } from '../../../utils/apirequester';
import { CesiumViewerContext } from '../cesiumviewer/CesiumViewer'

interface Props {
    id: number | null;
}
export default function Terrain(props: PropsWithChildren<Props>) {
    const viewer = useContext(CesiumViewerContext)
    // const [terrainProvider, setTerrainProvider] = useState<Cesium.CesiumTerrainProvider| null>(null)
    useEffect(() => {
        if (!viewer) {
            return;
        }
        if (props.id === null || props.id === 0) {
            const provider = new Cesium.EllipsoidTerrainProvider();
            viewer.terrainProvider = provider;
        } else {

            const url = RestApi.getBaseUrl() + `/api/v1/static/terrain/${props.id}`;
            const provider = new Cesium.CesiumTerrainProvider({
                url,
                requestVertexNormals: false,
                requestWaterMask: false,
            });
            viewer.terrainProvider = provider;
        }
    }, [props.id, viewer])
    return null;
}
