import { Redirect, useRouteMatch, Link, Switch, Route, useHistory } from 'react-router-dom'
import { RestApi } from '../../utils/apirequester'
import ProjectList from './components/project_list/ProjectList';


import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Uploader } from './components/uploader/Uploader';
import Cesium3DTilesetList from './components/cesium3dtileset_list/Cesium3DTilesetList';
import React from 'react';
import Geojson from './components/geojson/Geojson';
import Terrain from './components/terrain/Terrain';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function Dashboard() {

    const classes = useStyles();
    const token = RestApi.getToken();
    const { path, url } = useRouteMatch();
    const history = useHistory();
    function exit() {
        RestApi.removeToken();
        history.push('/login');
    }
    return (
        <>
            {
                token ? null :
                    <Redirect
                        to={{
                            pathname: "/login"
                        }}
                    />
            }
            <Uploader>
                <div className={classes.root}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                <Link to={path}>
                                    <Button>Ana Menü</Button>
                                </Link>
                                <Link to={`${url}/project`}>
                                    <Button>Projeler</Button>
                                </Link>
                                <Link to={`${url}/cesium3dtileset`}>
                                    <Button>Cesium3DTileset</Button>
                                </Link>
                                <Link to={`${url}/terrain`}>
                                    <Button>Terrainler</Button>
                                </Link>
                                <Link to={`${url}/geojson`}>
                                    <Button>Geojson</Button>
                                </Link>
                                <a href="/downloader" target="_black">
                                    <Button>Dosya Yükleme Uygulaması</Button>
                                </a>
                            </Typography>
                            <Button onClick={exit} color="inherit">Çıkış Yap</Button>
                        </Toolbar>
                    </AppBar>
                    <Switch>
                        <Route exact path={path}>
                            Üst Menüden Proje yada Terrain Seçiniz
                        </Route>
                        <Route path={`${url}/project`}>
                            <ProjectList />
                        </Route>
                        <Route path={`${url}/cesium3dtileset`}>
                            <Cesium3DTilesetList />
                        </Route>
                        <Route path={`${url}/geojson`}>
                            <Geojson />
                        </Route>
                        <Route path={`${url}/terrain`}>
                            <Terrain />
                        </Route>
                    </Switch>
                </div>
            </Uploader >
        </>
    )
}
