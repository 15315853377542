import React, { useEffect, useState, PropsWithChildren } from 'react'
import { Button, Card } from '@material-ui/core'
import { RestApi } from '../../../../utils/apirequester'
import { DataProject } from '../project_detail/ProjectDetail'
import CancelIcon from '@material-ui/icons/Cancel';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';


import { DataGeojsonProject } from '../../../../utils/models';

import './ProjectGeojson.scss';
import AddGeojsonToProject from '../add_geojson_to_project/AddGeojsonToProject';
import ProjectGeojsonItem from './project_geojson_item/ProjectGeojsonItem';
interface Props {
    project: DataProject;
}
export default function ProjectGeojson(props: PropsWithChildren<Props>) {

    const project = props.project;
    const { path, url } = useRouteMatch();
    const [geojsonProjectList, setGeojsonProjectList] = useState<DataGeojsonProject[]>([]);
    useEffect(() => {
        refreshGeojsons();
    }, [project.id]);

    return (
        <Card>
            <div className="ProjectGeojson">
                <Switch>
                    <Route exact path={path}>
                        <div className="header">
                            <h2>Geojson Listesi</h2>
                            <Link to={`${url}/addgeojson`}><Button>Geojson Ekle</Button></Link>
                        </div>
                        {geojsonProjectList.map(g => <ProjectGeojsonItem
                            key={g.id}
                            refreshGeojsons={refreshGeojsons}
                            geojsonProject={g}
                        />)}
                    </Route>
                    <Route exact path={`${path}/addgeojson`}>
                        <div className="header">
                            <h2>Geojson Ekle</h2>
                            <Link to={url}><Button><CancelIcon /></Button></Link>
                        </div>
                        <AddGeojsonToProject project={project} refreshGeojson={refreshGeojsons} />

                    </Route>
                </Switch>
            </div>

        </Card>
    );


    function refreshGeojsons() {
        setGeojsonProjectList([]);
        RestApi.get<DataGeojsonProject[]>(`/api/v1/project/${project.id}/geojson`)
            .then((res) => {
                setGeojsonProjectList(res);
            });
    }
}
