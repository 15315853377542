import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux';



import { ReduxState } from '../../../../../redux/store';
import { ITilesetExtended } from '../../../../../utils/models';
import { RestApi } from '../../../../../utils/apirequester';

import './ProjectTilesetSettings.scss';
import ProjectTilesetSettingsItem from './project_tileset_settings_item/ProjectTilesetSettingsItem';
interface Props {
    viewer: Cesium.Viewer | null;
}
export default function ProjectTilesetSettings(props: PropsWithChildren<Props>) {
    const tilesetList = useSelector<ReduxState, ITilesetExtended[]>((state => state.projectEdit.tilesetList))
    function onSaveConfirm(t: ITilesetExtended) {
        RestApi.put('/api/v1/tileset/' + t.id, t)
            .then(res => {
                console.log('RES', res);
            })
    }
    return (
        <div className="ProjectTilesetSettings">
            {
                tilesetList.map(t =>
                    <ProjectTilesetSettingsItem
                        key={t.id}
                        tileset={t}
                        onSaveConfirm={onSaveConfirm}
                    />)
            }
        </div>
    )
}
