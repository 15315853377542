import React, { useRef, useEffect, useState, PropsWithChildren } from 'react'
import './CesiumViewer.scss';

export const CesiumViewerContext = React.createContext<Cesium.Viewer | null>(null);

interface Props {
    callback?: (viewer: Cesium.Viewer) => void;
}
export default function CesiumViewer(props: PropsWithChildren<Props>) {
    const inputEl = useRef(document.createElement('div'));
    const [viewer, setViewer] = useState<Cesium.Viewer | null>(null)
    // console.log('HAYDARRR')
    useEffect(() => {
        // console.log('HAYDA')
        const v = new Cesium.Viewer(inputEl.current, {
            animation: false,
            homeButton: false,
            navigationHelpButton: false,
            infoBox: false,
            geocoder: false,
            baseLayerPicker: false,
            sceneModePicker: false,
            fullscreenButton: false,
            selectionIndicator: false,
            timeline: false,
            navigationInstructionsInitiallyVisible: false,
            imageryProvider: new Cesium.UrlTemplateImageryProvider({
                url: 'https://mt3.google.com/vt/lyrs=s@113&hl=tr&x={x}&y={y}&z={z}',
                maximumLevel: 22,
                minimumLevel: 1
            })
        });
        (v as any)._cesiumWidget._creditContainer.style.display = "none";
        (window as any).viewer = v;
        setViewer(v);
        if(props.callback) {
            props.callback(v);
        }
    }, []);
    return (
        <div className="CesiumViewer">
            <CesiumViewerContext.Provider value={viewer}>
            <div ref={inputEl} className="map"></div>
                {props.children}
            </CesiumViewerContext.Provider>
        </div>
    )
}
