import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import { RestApi } from '../../../../utils/apirequester';
import './Uploader.scss'
export interface FileSystemEntryType {
    done: boolean;
    value: [string, FileSystemFileHandle] | [string, FileSystemDirectoryHandle];
}
export interface FileSystemFileHandle {
    kind: 'file';
    name: string;
    getFile: () => Promise<File>;
}
export interface FileSystemDirectoryHandle {
    kind: 'directory';
    name: string;
    getFile: () => Promise<File>;
    entries: any //Iterator
}

export interface FileSystemResult {
    name: string;
    path: string;
    getFile: () => Promise<File>;
    fileHandler: FileSystemFileHandle;
}

interface UploadPatch {
    tileset_id: number;
    uploadPath: string;
    files: FileSystemResult[];
    infoCallback: (result: boolean) => void;
    title: string;
}
interface PatchItem extends UploadPatch {
    nextIndex: number;
    state: 'idle' | 'uploading' | 'uploaded';
}
export function convertFile2FileSystemResult(file: File, filePath: string) {
    const name = file.name;
    const getFile = async () => file;
    const fileHandler: FileSystemFileHandle = {
        kind: 'file',
        name,
        getFile
    }
    const systemresult: FileSystemResult = {
        name,
        path: filePath,
        getFile,
        fileHandler,
    }
    return systemresult;
}
export const UploaderContext = React.createContext<(patch: UploadPatch) => void>(null!);

const sleep = (time: number) => new Promise((res) => setTimeout(res, time));
interface State {
    patches: PatchItem[];
    progresRatio: number;
    progressColor: 'primary' | 'secondary';
}
export class Uploader extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            patches: [],
            progresRatio: 0,
            progressColor: 'primary'
        }
    }
    public startPatch = (patch: UploadPatch) => {
        // Bazen state güncellemleri async oluyor.
        // 
        /**
             this.setState({
                patches: clone,
            });
            yazılmasına rağmen patches boş bir array olabiliyor. firefoxda olmuştu.
            burada 1 sn sonra da tekrardan kontrol ediyoruz.
         */

        const inQueue = this.state.patches.some(a => a.tileset_id === patch.tileset_id);
        if (inQueue) {
            return;
        }
        const clone = this.state.patches.concat();
        clone.push({
            ...patch,
            nextIndex: 0,
            state: 'idle',
        });
        this.setState({
            patches: clone,
        });
        // this.checkList();
        setTimeout(() => {
            this.checkList();
        }, 1000)
    }
    private updatePatchItem = (patch: PatchItem) => {
        const index = this.state.patches.findIndex(a => a.tileset_id === patch.tileset_id);
        if (index === -1) {
            return;
        }
        const arr = this.state.patches;
        arr[index] = Object.assign({}, patch);
        this.setState({
            patches: arr.concat()
        })
    }
    private onProgressEvent = (e: ProgressEvent) => {
        const timeStamp = e.timeStamp;
        const progresRatio = (e.loaded / e.total) * 100
        // console.log('timeStamp', timeStamp)
        // console.log('progresRatio', progresRatio);
        this.setState({ progresRatio })
    }
    private startUploadingItem = async (patch: PatchItem) => {
        const nextFile = patch.files[patch.nextIndex];
        if (!nextFile) {
            patch.state = 'uploaded';
            this.updatePatchItem(patch);
            patch.infoCallback(true);
            this.checkList();
            return;
        }
        if (patch.state !== 'uploading') {
            patch.state = 'uploading';
            this.updatePatchItem(patch);
        }
        const file = await nextFile.fileHandler.getFile();
        this.setState({ progresRatio: 0 });
        const formData = new FormData();
        formData.append('file', file);
        formData.append('path', nextFile.path);
        const url = patch.uploadPath;
        try {
            
            console.log('uploadinggg');
            await RestApi.multiPartPostV2(url, formData, this.onProgressEvent);
            console.log('uploaded');
            // await sleep(200);
            patch.nextIndex++;
            patch.state = 'idle';
            this.updatePatchItem(patch);
            this.checkList();
        } catch (error) {
            console.log('failure');
            patch.state = 'idle';
            this.updatePatchItem(patch);
            this.networkError();
        }
    }

    private networkError = async () => {
        console.log('networkError start')
        this.setState({ progressColor: 'secondary' });
        
        await sleep(3000);
        this.setState({ progressColor: 'primary' });
        console.log('networkError end')
        await sleep(3000);
        console.log('networkError checkList')
        this.checkList();
    }
    private checkList = () => {
        const isUploading = this.state.patches.some(a => a.state === 'uploading');
        if (isUploading) {
            console.log('checkList stopped isUploading true')
            return;
        }
        // console.log('checkList patched', JSON.stringify(this.state.patches, null, 2))
        const idle = this.state.patches.find(a => a.state === 'idle');
        if (!idle) {
            console.log('checkList idle')
            return;
        }
        this.startUploadingItem(idle);
    }
    getItemState = (state: PatchItem['state']) => {
        if (state === 'idle') {
            return <PlayArrowIcon />;
        }
        if (state === 'uploading') {
            return <PauseIcon />;
        }
        if (state === 'uploaded') {
            return <CloudDoneIcon />;
        }
    }
    render() {
        const { progresRatio, progressColor } = this.state;
        return <UploaderContext.Provider value={this.startPatch}>
            {this.props.children}
            <div className="Uploader">
                {this.state.patches.map(a => {
                    const ratio = (a.nextIndex) * 100 / a.files.length;
                    return <div key={a.tileset_id}>
                        <Card>
                            <div className="item">
                                <h4 style={{ margin: '8px 0' }}>
                                    {a.title}
                                </h4>
                                <LinearProgress color={progressColor} variant="determinate" value={progresRatio} />
                                <br />
                                <LinearProgress variant="determinate" value={ratio} />
                                {a.nextIndex} / {a.files.length}
                                <br />
                                {this.getItemState(a.state)}
                            </div>

                        </Card>
                    </div>
                })}
            </div>
        </UploaderContext.Provider>
    }

}

