

export class CustomGeojsonDataStore {
    private dsPromise!: Promise<Cesium.GeoJsonDataSource>;
    constructor(private viewer: Cesium.Viewer,
        private url: string,
        private visible: boolean,
        private classification: boolean,
        private styleColor: string | null,

    ) {
        this.classification = classification;
        this.initDatastore();
    }

    setViewer = (v: Cesium.Viewer) => {
        this.viewer = v;
        this.dataUpdated();
    }
    private logState = async () => {
        const viewer = this.viewer;
        // const ds = await this.dsPromise;
    }
    setVisible = (v: boolean) => {
        this.visible = v;
        this.logState();
        this.dataUpdated();
        this.logState();
    }

    setClassification = async (c: boolean) => {
        this.classification = c;
        this.reloadAndWaitUntilInstall();
    }

    setColor = async (c: string | null) => {
        this.styleColor = c;
        this.reloadAndWaitUntilInstall();
    }
    private isIdle = true;
    private needToReDraw = false;
    private reloadAndWaitUntilInstall = async () => {
        if (this.isIdle === false) {
            this.needToReDraw = true;
            return;
        }
        this.isIdle = false;

        await this.removeOldDatastore();
        await this.initDatastore();
        this.dataUpdated();

        this.isIdle = true;
        if(this.needToReDraw) {
            this.needToReDraw = false;
            this.reloadAndWaitUntilInstall();
        }
    }
    private getColor = (): Cesium.Color | null => {
        if (!this.styleColor) {
            return null;
        }
        return Cesium.Color.fromCssColorString(this.styleColor);
    }

    private initDatastore = async () => {
        const color = this.getColor();
        if (color === null) {
            this.dsPromise = Cesium.GeoJsonDataSource.load(this.url, {
                clampToGround: this.classification,
            });
        } else {
            this.dsPromise = Cesium.GeoJsonDataSource.load(this.url, {
                clampToGround: this.classification,
                stroke: color,
                fill: color,
                strokeWidth: 3,
                markerSymbol: '?'
            });
        }

        this.viewer.dataSources.add(this.dsPromise);
        await this.dsPromise;
        // this.logState();
    }
    private removeOldDatastore = async () => {
        const ds = await this.dsPromise
        this.viewer.dataSources.remove(ds);
    }
    private dataUpdated = async () => {
        const ds = await this.dsPromise;
        ds.show = this.visible!;
    }

    public destroy = async () => {
        const ds = await this.dsPromise
        this.viewer.dataSources.remove(ds);
    }
}