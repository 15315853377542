import { useFormik } from 'formik';
import { TextField, Button, FormControl, Select, MenuItem } from '@material-ui/core';
import * as Yup from 'yup';
import { DataProject } from '../project_detail/ProjectDetail';
import { RestApi } from '../../../../utils/apirequester';
import { useHistory } from 'react-router-dom';
import { PropsWithChildren, useEffect, useState } from 'react';
import { DataGeojson } from '../../../../utils/models';
import './AddGeojsonToProject.scss';


const GeojsonSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    geojson_id: Yup.number()
        .min(1, 'Geojson Seçiniz')
});


interface Props {
    project: DataProject;
    refreshGeojson: () => void;
}

export default function AddGeojsonToProject(props: PropsWithChildren<Props>) {
    const history = useHistory();

    const [geojsonList, setGeojsonList] = useState<DataGeojson[]>([])
    useEffect(() => {
        RestApi.get<DataGeojson[]>('/api/v1/geojson').then(list => {
            setGeojsonList(list);
        })
    }, [])
    
    
    const formik = useFormik({
        initialValues: {
            name: '',
            geojson_id: 0,
        },
        validationSchema: GeojsonSchema,
        onSubmit: (values) => {
            const body = {
                name: values.name,
                project_id: props.project.id,
                geojson_id: values.geojson_id,
            };
            RestApi.post<any>('/api/v1/geojsonproject', body)
                .then(res => {
                    props.refreshGeojson();
                    history.goBack();
                });
        }
    })
    
    return (
        <form className="AddGeojsonToProject" onSubmit={formik.handleSubmit}>
            <FormControl>
                <TextField
                    name="name"
                    label="Geojson Adı"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.touched.name && typeof formik.errors.name === 'string'}
                />
                <Select
                    name="geojson_id"
                    label="Geojson"
                    value={formik.values.geojson_id}
                    onChange={formik.handleChange}>
                    <MenuItem value={0}>Geojson Seçiniz</MenuItem>
                    {
                        geojsonList.map(g =>
                            <MenuItem
                                key={g.id}
                                value={g.id}
                            >
                                ({g.id}) {g.name}
                            </MenuItem>)
                    }
                </Select>
            </FormControl>

            <Button type="submit">Geojson'ı Projeye Ekle</Button>
        </form>
    )
}
