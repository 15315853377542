import React, { PropsWithChildren } from 'react'
import './GeojsonEdit.scss';

import { Button, FormControl, TextField } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { RestApi } from '../../../../../utils/apirequester';
import { DataCesium3DTileset, DataGeojson} from '../../../../../utils/models';
import { getStateElement } from '../../../../../utils/util';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Prompt from '../../../../../components/prompt/Prompt';
import DeleteIcon from '@material-ui/icons/Delete';
import { UploaderContext } from '../../uploader/Uploader';
import { showOpenFile } from '../../uploader/OpenFolderPicker';
const GeojsonEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Çok Kısa')
        .max(50, 'Çok Uzun')
        .required('Zorunlu')
})
interface Props {
    reloadCallback: () => void;
}
export default function GeojsonEdit(props: PropsWithChildren<Props>) {
    const uploaderContext = useContext(UploaderContext);
    const { id } = useParams<any>();
    const [geojson, setGeojson] = useState<DataGeojson | undefined>(undefined);
    const [deleteForm, setDeleteForm] = useState(false);
    const [deleteDataForm, setDeleteDataForm] = useState(false);
    const history = useHistory();

    const formik = useFormik({
        initialValues: { name: '' },
        validationSchema: GeojsonEditSchema,
        onSubmit: (values) => {
            RestApi.put<DataCesium3DTileset>('/api/v1/geojson/' + id, values)
                .then(res => {
                    reloadloadData();
                    props.reloadCallback();
                });
        }
    });
    useEffect(() => { reloadloadData(); }, [id]);
    async function reloadloadData() {
        const res = await RestApi.get<DataGeojson>('/api/v1/geojson/' + id)
        formik.setValues(res);
        setGeojson(res);
    }
    async function onVeriUpload() {
        try {
            const file = await showOpenFile();
            uploaderContext({
                tileset_id: id,
                uploadPath: `/api/v1/geojson/${id}/uploadfile`,
                files: [file],
                infoCallback: startVerify,
                title: geojson?.name || '',
            });
        } catch (error) {
            console.log('ERROR', error);
            alert(error.message);
        }
    }

    async function startVerify() {
        await RestApi.get<any>(`/api/v1/geojson/${id}/verify`);
        await reloadloadData();
    }
    async function deleteGeojson() {
        await RestApi.delete<any>(`/api/v1/geojson/${id}`);
        history.push('/dashboard/geojson');
        props.reloadCallback();
    }
    async function deleteDataOnly() {
        await RestApi.delete<any>(`/api/v1/geojson/${id}/dataonly`);
        await reloadloadData();
    }
    if(!geojson) {
        return null;
    }
    return (
        <div className="GeojsonEdit">
            <div className="header">
                <h3>{geojson.name}</h3>
                <Button onClick={() => setDeleteForm(true)}><DeleteIcon /></Button>
            </div>
            <div className="data">
                <Button onClick={onVeriUpload}>Veri Yükle</Button>
                <Button color="primary" onClick={startVerify}>Veri Kontrol Et</Button>
                <Button color="secondary" onClick={() => setDeleteDataForm(true)}>Veri Sil</Button>
                {getStateElement(geojson.state)}
            </div>
            <form onSubmit={formik.handleSubmit}>
                <h3>Güncelleme</h3>
                <FormControl>
                    <TextField
                        id="name"
                        label="Geojson Adı"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={formik.touched.name && typeof formik.errors.name === 'string'}
                    />
                </FormControl>

                <Button type="submit">Güncelle</Button>
            </form>
            <Prompt
                open={deleteForm}
                callback={(res) => {
                    setDeleteForm(false);
                    if (res) {
                        deleteGeojson();
                        return;
                    }
                }}
                title="Geojson Siliniyor"
                question="Dikkat Geojson siliniyor. Bu Geojson herhangi bir projede kullanılıyor ise o projeden de bu Geojson Silinecektir"
                agreeText="Sil"
                disAgreeText="İptal"
            />
            <Prompt
                open={deleteDataForm}
                callback={(res) => {
                    setDeleteDataForm(false);
                    if (res) {
                        deleteDataOnly();
                        return;
                    }
                }}
                title="Geojson Verileri"
                question="Dikkat Geojson Verileri siliniyor. geodata.json gibi dosyayı tekrardan yüklemeniz gerekir"
                agreeText="Sil"
                disAgreeText="İptal"
            />
        </div>
    )
}
