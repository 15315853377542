// import React from 'react';
// import logo from './logo.svg';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  // Link,
  // useParams,
  // useRouteMatch

} from "react-router-dom";
import Login from './routes/login/Login';
import Dashboard from './routes/dashboard/Dashboard';
import IFrame from './routes/iframe/IFrame';

function App() {
  return (
      <Router>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="/dashboard" >
            <Dashboard />
          </Route>
          <Route path="/iframe" >
            <IFrame />
          </Route>
          
          <Route>
            <Redirect to="/dashboard" />
          </Route>

        </Switch>
      </Router>
  );
}

export default App;
