import React, { useEffect, useState } from 'react'
import {
    AppBar,
    IconButton,
    Toolbar,
    Tooltip,
    Button
} from '@material-ui/core'
import { NavLink, Route, Switch, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import CesiumViewer from '../../../../components/cesium_viewer_depended/cesiumviewer/CesiumViewer';

import { RestApi } from '../../../../utils/apirequester';

import { useSelector, useDispatch } from 'react-redux';

import { actionProjectEditSetGeojsonList, actionProjectEditSetProject, actionProjectEditSetTerrainList, actionProjectEditSetTilesetList } from '../../../../redux/actions/project_edit';
import ProjectEditSettings from './project_edit_project_settings/ProjectEditSettings';
import { ITileset, IProject, ITilesetExtended, DataGeojsonProject, DataTerrain } from '../../../../utils/models';
import CameraPosition from '../../../../components/cesium_viewer_depended/cameraposition/CameraPosition';
import { ReduxState } from '../../../../redux/store';
import Shadow from '../../../../components/cesium_viewer_depended/shadow/Shadow';
import ProjectTilesetSettings from './project_edit_tileset_settings/ProjectTilesetSettings';
import Cesium3DTileset from '../../../../components/cesium_viewer_depended/cesium3dtileset/Cesium3DTileset';

import './ProjectEdit.scss';
import GeojsonLoader from '../../../../components/cesium_viewer_depended/geojsonloader/GeojsonLoader';
import ProjectEditGeojsonSettings from './project_edit_geojson_settings/ProjectEditGeojsonSettings';
// import Terrain from '../terrain/Terrain';
import Terrain from '../../../../components/cesium_viewer_depended/terrain/Terrain';

export default function ProjectEdit() {
    const { url, path } = useRouteMatch();
    const history = useHistory();
    const projectId = useParams<any>().project_id;
    const [viewer, setViewer] = useState<Cesium.Viewer | null>(null);

    const project = useSelector<ReduxState>((state => state.projectEdit.project)) as IProject
    const tilesetList = useSelector<ReduxState>((state => state.projectEdit.tilesetList)) as ITilesetExtended[];
    const geojsonList = useSelector<ReduxState>((state => state.projectEdit.geojsonList)) as DataGeojsonProject[];


    const dispatch = useDispatch();

    async function loadData() {
        const [project, tilesetList, terrainList, geojsonList] = await Promise.all([
            RestApi.get<IProject>(`/api/v1/project/${projectId}`),
            RestApi.get<ITileset[]>(`/api/v1/project/${projectId}/tileset`),
            RestApi.get<DataTerrain[]>('/api/v1/terrain'),
            RestApi.get<DataGeojsonProject[]>(`/api/v1/project/${projectId}/geojson`),
        ]);
        dispatch(actionProjectEditSetProject(project));
        dispatch(actionProjectEditSetGeojsonList(geojsonList));
        dispatch(actionProjectEditSetTerrainList(terrainList));
        dispatch(actionProjectEditSetTilesetList(
            tilesetList.map(t => ({ zoomTilesetTriggerValue: 0, ...t }))
        ));
    }
    useEffect(() => {
        loadData()
    }, [projectId])
    function goBackToProjectDetailPath() {
        history.push(`/dashboard/project/${projectId}`);
    }
    return (
        <div className="ProjectEdit">
            <AppBar position="relative">
                <Toolbar className="toolbar">
                    <NavLink to={`${url}/project`}>
                        <Button>Proje</Button>
                    </NavLink>
                    <NavLink to={`${url}/tileset`}>
                        <Button>Tileset</Button>
                    </NavLink>
                    <NavLink to={`${url}/geojson`}>
                        <Button>Geojson</Button>
                    </NavLink>
                    <span className="empty"></span>
                    <Tooltip title="Kapat">
                        <IconButton onClick={goBackToProjectDetailPath} edge="start" color="inherit" aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>

            <div className="body">
                <div className="side">
                    <Switch>
                        <Route path={`${path}/tileset`}>
                            <ProjectTilesetSettings viewer={viewer} />
                        </Route>
                        <Route path={`${path}/project`}>
                            <ProjectEditSettings viewer={viewer} />
                        </Route>
                        <Route path={`${path}/geojson`}>
                            <ProjectEditGeojsonSettings viewer={viewer} />
                        </Route>
                    </Switch>
                </div>
                <div className="map">
                    <CesiumViewer callback={setViewer}> {
                        project ? <>
                            <CameraPosition
                                camera_direction={project.camera_direction}
                                camera_up={project.camera_up}
                                camera_position={project.camera_position}
                            />
                            <Shadow shadow={project.shadow} />
                            {tilesetList.map(t =>
                                <Cesium3DTileset
                                    key={t.id}
                                    tilesetId={t.TilesetId}
                                    visible={t.visible}
                                    maximum_screen_space_error={t.maximum_screen_space_error}
                                    double_side_render={t.double_side_render}
                                    style={t.style}
                                    offset_x={t.offset_x}
                                    offset_y={t.offset_y}
                                    offset_z={t.offset_z}
                                    zoomTilesetTriggerValue={t.zoomTilesetTriggerValue}

                                />)
                            }
                            {geojsonList.map(g =>
                                <GeojsonLoader
                                    key={g.id}
                                    geojsonId={g.geojson_id}
                                    visible={g.visible}
                                    classification={g.classify}
                                    style={g.style}
                                />)}
                            <Terrain id={project.TerrainId} />
                        </> : null
                    }
                    </CesiumViewer>
                </div>
            </div>
        </div>
    )
}
