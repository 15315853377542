import { IProject, ITileset, DataGeojsonProject, ITilesetExtended, DataTerrain } from "../../utils/models";
import { DefaultAction } from "../util";

export interface ActionProjectEditUpdateTileset extends DefaultAction {
    type: 'PROJECT_EDIT_UPDATE_TILESET',
    payload: {
        id: number;
        properties: {
            [key: string]: any;
        }
    }
}

export const actionProjectEditUpdateTileset = (id: number, properties: any): ActionProjectEditUpdateTileset => ({
    type: 'PROJECT_EDIT_UPDATE_TILESET',
    payload: {
        id,
        properties
    }
});
export const actionProjectTilesetZoom = (id: number, value: number) => ({
    type: 'PROJECT_EDIT_UPDATE_TILESET',
    payload: {
        id,
        properties: {
            zoomTilesetTriggerValue: value
        }
    }
});

export const actionProjectEditSetProject = (project: IProject): DefaultAction => ({
    type: 'PROJECT_EDIT_SET_PROJECT',
    payload: project
})
export const actionProjectEditUpdateProject = (properties: any): DefaultAction => ({
    type: 'PROJECT_EDIT_UPDATE_PROJECT',
    payload: properties
})
export const actionProjectEditSetTilesetList = (tilesetList: ITilesetExtended[]): DefaultAction => ({
    type: 'PROJECT_EDIT_SET_TILESETLIST',
    payload: tilesetList
})
export const actionProjectEditSetGeojsonList = (geojsonList: DataGeojsonProject[]): DefaultAction => ({
    type: 'PROJECT_EDIT_SET_GEOJSONLIST',
    payload: geojsonList
})
export const actionProjectEditSetTerrainList = (terrainList: DataTerrain[]): DefaultAction => ({
    type: 'PROJECT_EDIT_SET_TERRAINLIST',
    payload: terrainList
})



export interface ActionProjectEditUpdateGeojson extends DefaultAction {
    type: 'PROJECT_EDIT_UPDATE_GEOJSON',
    payload: {
        id: number;
        properties: {
            [key: string]: any;
        }
    }
}

export const actionProjectEditUpdateGeojson = (id: number, properties: any): ActionProjectEditUpdateGeojson => ({
    type: 'PROJECT_EDIT_UPDATE_GEOJSON',
    payload: {
        id,
        properties
    }
});
// export const actionProjectEditUpdateGeojson = (id: number, properties: any): ActionProjectEditUpdateGeojson => {
//     console.log('HOPPALA')
//     return {
//         type: 'PROJECT_EDIT_UPDATE_GEOJSON',
//         payload: {
//             id,
//             properties
//         }
//     }
// };


