import { FileSystemDirectoryHandle, FileSystemEntryType, FileSystemFileHandle, FileSystemResult } from "./Uploader";

const GeojsonOptions = {
    description: 'Geojson',
    accept: {
        'application/json': ['.json', '.geojson']
    },
}
const GeotiffOptions = {
    description: 'Geotiff',
    accept: {
        'application/x-geotiff': ['.geotiff', '.tif', '.tiff']
    },
}
export async function showOpenFile(type: 'geojson' | 'geotif' = 'geojson'): Promise<FileSystemResult> {
    let opt: any = GeojsonOptions;
    if(type === 'geotif') {
        opt = GeotiffOptions;
    }
    const options = {
        types: [opt],
        multiple: false
    }
    const showOpenFilePicker = (window as any).showOpenFilePicker as (opt: any) => Promise<FileSystemFileHandle[]>;
    const [file] = await showOpenFilePicker(options);
    console.log('file', file);
    return {
        name: file.name,
        path: '',
        getFile: file.getFile,
        fileHandler: file
    }
    // return file;
}

// export function startSingleFile(file: File): FileSystemResult {
//     return {
//         name: file.name,
//         path: '',
//         getFile: async () => file,
//         fileHandler: {
//             kind: 'file',
//             name: file.name,
//             getFile: async () => file,
//         }
//     }
// }
export async function startDirectorySearch(): Promise<FileSystemResult[]> {
    const w = window as any;
    console.log('directoryOpen', w.directoryOpen);
    if (typeof w['showDirectoryPicker'] !== 'function') {
        alert('Tarayıcınız çoklu dosya yükleme işlemini desteklemiyor.')
        throw new Error('');
        // return [];
    }
    const dirhandle = await w.showDirectoryPicker() as FileSystemDirectoryHandle;
    const res = await getDir('.', dirhandle);
    return res;
}
async function getDir(parentFolder: string, dirhandle: FileSystemDirectoryHandle): Promise<any[]> {
    const entries = dirhandle.entries()
    const arr: FileSystemResult[] = [];
    while (true) {
        const item = await entries.next() as FileSystemEntryType;
        if (!item) {
            return arr;
        }
        if (!item.value) {
            return arr;
        }
        const fileHandler = item.value[1];
        const kind = fileHandler.kind;
        // console.log(fileHandler.name);
        if (kind === 'file') {
            arr.push({
                path: parentFolder + '/' + fileHandler.name,
                name: fileHandler.name,
                getFile: fileHandler.getFile,
                fileHandler: fileHandler as FileSystemFileHandle,
            })
        } else if (kind === 'directory') {
            // console.log('DIR', fileHandler)
            const parentFolderName = parentFolder + '/' + fileHandler.name;
            const res = await getDir(parentFolderName, fileHandler as FileSystemDirectoryHandle)
            arr.push(...res);
        }

    }
}
