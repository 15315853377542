import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { PropsWithChildren, useState } from 'react'
import './IFrameTileset.scss';
interface Props {
    name: string;
    defaultVisible: boolean;
    onVisibleChange: (checked: boolean) => void;

}
export default function IFrameTileset(props: PropsWithChildren<Props>) {
    const [checked, setChecked] = useState(props.defaultVisible);
    function onChecboxChange(event: any, c: boolean) {
        setChecked(c);
        props.onVisibleChange(c);
    }
    return (
        <div className="IFrameTileset">
            <FormControlLabel
                control={<Checkbox checked={checked} onChange={onChecboxChange} name={props.name} />}
                label={props.name}
            />
        </div>
    )
}
