import React, { useState, useEffect } from 'react'
import { Button, Card, List, ListItem, ListItemText } from '@material-ui/core';


import { Link, NavLink, useRouteMatch, Switch, Route } from 'react-router-dom'

import './Geojson.scss';
import GeojsonAdd from './geojson_add/GeojsonAdd';
import { DataGeojson } from '../../../../utils/models';
import { RestApi } from '../../../../utils/apirequester';
import GeojsonEdit from './geojson_edit/GeojsonEdit';

export default function Geojson() {
    const { path, url } = useRouteMatch();

    const [geojsonList, setGeojsonList] = useState<DataGeojson[]>([])

    function refreshGeojson() {
        RestApi.get<DataGeojson[]>('/api/v1/geojson').then(setGeojsonList);

    }
    useEffect(() => {
        refreshGeojson();
    }, [])

    return (
        <div className="Geojson">
            <div className="left">
                <Link to={`${url}/add`}>
                    <Button> Geojson Oluştur </Button>
                </Link>
                <List>
                    {geojsonList.map(geojson =>
                        <NavLink
                            to={`${path}/${geojson.id}`}
                            activeStyle={{
                                fontWeight: "bold",
                                color: "red"
                            }}
                            key={geojson.id}
                        >
                            <ListItem button>
                                <ListItemText primary={geojson.name} />
                            </ListItem>
                        </NavLink>
                    )}

                </List>
            </div>
            <div className="right">
                <Card style={{ padding: '16px' }}>
                    <Switch>
                        <Route exact path={path}>
                            Sol Menüden Geojson Seçiniz
                            veya Geojson ekleyiniz

                        </Route>
                        <Route exact path={`${path}/add`}>
                            <GeojsonAdd onComplete={refreshGeojson} />
                        </Route>
                        <Route path={`${path}/:id`}>
                            <GeojsonEdit reloadCallback={refreshGeojson} />
                        </Route>
                    </Switch>
                </Card>

            </div>
        </div>
    )
}
