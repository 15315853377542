import React, { PropsWithChildren, useState } from 'react'
import {
    ListItem,
    IconButton,
    Switch,
    Checkbox,
    FormControlLabel,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Tooltip,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';


import './ProjectEditGeojsonSettingsItem.scss';
import { DataGeojsonProject } from '../../../../../../utils/models';
import { useDispatch } from 'react-redux';
import { actionProjectEditUpdateGeojson } from '../../../../../../redux/actions/project_edit';
import ColorPicker from '../../../../../../components/colorpicker/ColorPicker';
interface Props {
    geojson: DataGeojsonProject;
    onSaveConfirm: (geojson: DataGeojsonProject) => void;
}
export default function ProjectEditGeojsonSettingsItem(props: PropsWithChildren<Props>) {
    const geojson = props.geojson;
    const dispatch = useDispatch();
    const [saveDialog, setSaveDialog] = useState(false);

    function onColorChange(value: string | null) {
        dispatch(actionProjectEditUpdateGeojson(geojson.id, {
            style: value
        }))
    }

    function onChange(event: any, key = 'value') {
        let body = null;
        if (event.target.type === 'number') {
            body = {
                [event.target.name]: +event.target[key]
            };
        } else {
            body = {
                [event.target.name]: event.target[key]
            }
        }
        dispatch(actionProjectEditUpdateGeojson(geojson.id, body))
    }
    function saveConfirm() {
        setSaveDialog(false);
        props.onSaveConfirm(geojson);
    }
    function onZoom() {

    }

    return (
        <div className="ProjectEditGeojsonSettingsItem">



            <ListItem className="item">
                <div className="row">
                    <TextField
                        name="name"
                        label="Tileset Adı"
                        type="text"
                        value={geojson.name}
                        onChange={onChange}
                    />
                    <Switch name="visible"
                        checked={geojson.visible}
                        onChange={(e) => onChange(e, 'checked')}
                    />
                </div>
                <span>
                    <IconButton onClick={() => onZoom()}>
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton aria-label="save" onClick={() => setSaveDialog(true)}>
                        <SaveIcon />
                    </IconButton>
                </span>
                <span>
                    <ColorPicker
                        value={geojson.style}
                        onChange={onColorChange}
                    />
                    <Tooltip title="3D Veriler ile Sınıflandırma olsun mu ">
                        <FormControlLabel
                            name="classify"
                            value="start"
                            control={<Checkbox
                                color="secondary"
                                checked={geojson.classify}
                                onChange={(e) => onChange(e, 'checked')}
                            />}
                            label="Sınıflandırma"
                            labelPlacement="start"
                        />
                    </Tooltip>
                </span>
            </ListItem>

            <Dialog open={saveDialog}
                onClose={() => setSaveDialog(false)}
            >
                <DialogTitle id="responsive-dialog-title">Geojson Güncelleme</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Geojson bilgileri güncellenecek emin misiniz ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setSaveDialog(false)}>
                        İptal
                    </Button>
                    <Button color="primary" onClick={saveConfirm}>
                        Onayla
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
