import { useFormik } from 'formik';
import { TextField, Button, FormControl, Select, MenuItem } from '@material-ui/core';
import './TilesetAdd.scss';
import * as Yup from 'yup';
import { DataProject } from '../project_detail/ProjectDetail';
import { RestApi } from '../../../../utils/apirequester';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DataCesium3DTileset } from '../../../../utils/models';

const TilesetSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    TilesetId: Yup.number()
        .min(1, 'Cesium3DTileset Seçiniz')
});

interface Props {
    project: DataProject;
    refreshTileset: () => void;
}
export default function TilesetAdd(props: Props) {
    const history = useHistory();
    const [cesium3DTilesetList, setCesium3DTilesetList] = useState<DataCesium3DTileset[]>([]);
    useEffect(() => {
        RestApi.get<DataCesium3DTileset[]>('/api/v1/cesium3dtileset').then(list => {
            setCesium3DTilesetList(list);
        })
    }, [])
    const formik = useFormik({
        initialValues: {
            name: '',
            TilesetId: 0,
        },
        validationSchema: TilesetSchema,
        onSubmit: (values) => {
            const body = {
                ProjectId: props.project.id,
                name: values.name,
                TilesetId: values.TilesetId,
            };
            RestApi.post<any>('/api/v1/tileset', body)
                .then(res => {
                    props.refreshTileset();
                    history.goBack();
                });
        }
    })
    return (
        <form className="TilesetAdd" onSubmit={formik.handleSubmit}>
            <FormControl>
                <TextField
                    id="name"
                    label="Katman Adı"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.touched.name && typeof formik.errors.name === 'string'}
                />
                <Select
                    id="TilesetId"
                    name="TilesetId"
                    label="Cesium3DTileset"
                    value={formik.values.TilesetId}
                    onChange={formik.handleChange}>
                    <MenuItem value={0}>Cesium3DTileset Seçiniz</MenuItem>
                    {
                        cesium3DTilesetList.map(tileset =>
                            <MenuItem
                                key={tileset.id}
                                value={tileset.id}
                            >
                                ({tileset.id}) {tileset.name}
                            </MenuItem>)
                    }
                </Select>
            </FormControl>

            <Button type="submit">Tileseti Oluştur</Button>
        </form>
    )
}
