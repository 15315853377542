import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { CesiumViewerContext } from '../cesiumviewer/CesiumViewer'

interface Poi {
    coordinates: number[];
}
interface Props {
    camera_position: Poi | null;
    camera_direction: Poi | null;
    camera_up: Poi | null;
}
export default function CameraPosition(props: PropsWithChildren<Props>) {
    const viewer = useContext(CesiumViewerContext)

    useEffect(() => {
        if(!viewer) {
            return;
        }
        if(props.camera_direction === null) {
            return;
        }
        if(props.camera_up === null) {
            return;
        }
        if(props.camera_position === null) {
            return;
        }
        const [lon, lat, alt] = (props.camera_position as any).coordinates as number[];
        const destination = Cesium.Cartesian3.fromDegrees(lon, lat, alt);
        viewer.camera.setView({
            destination,
            orientation: {
                direction: new Cesium.Cartesian3(...props.camera_direction.coordinates),
                up: new Cesium.Cartesian3(...props.camera_up.coordinates),
            }
        })
    }, [
        props.camera_up,
        props.camera_direction,
        props.camera_position,
    ])
    return null;
}
