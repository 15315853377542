import React from 'react';
import { Route, Switch } from 'react-router'
import { useRouteMatch } from 'react-router-dom';
import CesiumViewer from '../../components/cesium_viewer_depended/cesiumviewer/CesiumViewer';
import ProjectViewer from './projectviewer/ProjectViewer';
export default function IFrame() {
    const { url, path } = useRouteMatch();
    return (
        <div style={{height: '100vh', width: '100vw'}}>
            <Switch>
                <Route exact path={url}>
                    PROJE SEÇİNİZ
                </Route>
                <Route path={`${path}/:id`}>
                    <CesiumViewer>
                        <ProjectViewer/>
                    </CesiumViewer>
                </Route>
            </Switch>
        </div>
    )
}
