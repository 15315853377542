import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { DataGeojsonProject, IProject, ITileset, ITilesetExtended } from '../../../utils/models';
import { RestApi } from '../../../utils/apirequester';
import IFramePanel from '../iframe_panel/IFramePanel';
import IFrameTileset from '../iframe_tileset/IFrameTileset';
import { useSelector, useDispatch } from 'react-redux';
import Shadow from '../../../components/cesium_viewer_depended/shadow/Shadow';

import './ProjectViewer.scss';
import { ReduxState } from '../../../redux/store';
import { actionProjectEditSetGeojsonList, actionProjectEditSetProject, actionProjectEditSetTilesetList, actionProjectEditUpdateGeojson, actionProjectEditUpdateTileset } from '../../../redux/actions/project_edit';
import Cesium3DTileset from '../../../components/cesium_viewer_depended/cesium3dtileset/Cesium3DTileset';
import CameraPosition from '../../../components/cesium_viewer_depended/cameraposition/CameraPosition';
import GeojsonLoader from '../../../components/cesium_viewer_depended/geojsonloader/GeojsonLoader';
import Terrain from '../../../components/cesium_viewer_depended/terrain/Terrain';


export default function ProjectViewer() {
    const projectId = +useParams<any>().id;

    const project = useSelector<ReduxState>((state => state.projectEdit.project)) as IProject
    const tilesetList = useSelector<ReduxState>((state => state.projectEdit.tilesetList)) as ITilesetExtended[];
    const geojsonList = useSelector<ReduxState>((state => state.projectEdit.geojsonList)) as DataGeojsonProject[];


    const dispatch = useDispatch();
    async function loadData() {
        const [project, tilesetList, geojsonList] = await Promise.all([
            RestApi.get<IProject>(`/api/v1/project/${projectId}`),
            RestApi.get<ITileset[]>(`/api/v1/project/${projectId}/tileset`),
            RestApi.get<DataGeojsonProject[]>(`/api/v1/project/${projectId}/geojson`),
        ]);
        dispatch(actionProjectEditSetProject(project));
        dispatch(actionProjectEditSetGeojsonList(geojsonList));
        dispatch(actionProjectEditSetTilesetList(
            tilesetList.map(t => ({ zoomTilesetTriggerValue: 0, ...t }))
        ));
    }
    function changeVisibility(id: number, visible: boolean) {
        dispatch(actionProjectEditUpdateTileset(id, {
            visible
        }))
    }
    function changeGeojsonVisibility(id: number, visible: boolean) {
        dispatch(actionProjectEditUpdateGeojson(id, {
            visible
        }))
    }
    useEffect(() => {
        loadData()
    }, [projectId])

    if (!project) {
        return null;
    }
    return (
        <div className="ProjectViewer">
            {project.iframe_panel ?
                <IFramePanel>
                    {tilesetList.map(t => <IFrameTileset
                        key={t.id}
                        name={t.name}
                        defaultVisible={t.visible}
                        onVisibleChange={(c) => changeVisibility(t.id, c)}
                    />)}
                    {geojsonList.map(g => <IFrameTileset
                        key={g.id}
                        name={g.name}
                        defaultVisible={g.visible}
                        onVisibleChange={(v) => changeGeojsonVisibility(g.id, v)}
                    />)}

                </IFramePanel>
                : null
            }
            {project ? <>
                <Shadow shadow={project.shadow}></Shadow>
                <CameraPosition
                    camera_direction={project.camera_direction}
                    camera_up={project.camera_up}
                    camera_position={project.camera_position}
                />
                {tilesetList.map(t =>
                    <Cesium3DTileset
                        key={t.id}
                        tilesetId={t.TilesetId}
                        visible={t.visible}
                        maximum_screen_space_error={t.maximum_screen_space_error}
                        double_side_render={t.double_side_render}
                        style={t.style}
                        offset_x={t.offset_x}
                        offset_y={t.offset_y}
                        offset_z={t.offset_z}
                    />)
                }
                {geojsonList.map(g =>
                    <GeojsonLoader
                        key={g.id}
                        geojsonId={g.geojson_id}
                        visible={g.visible}
                        classification={g.classify}
                        style={g.style}
                    />)
                }
                <Terrain id={project.TerrainId} />
            </> : null}
        </div>
    )
}
