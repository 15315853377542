import { Button, Card, List, ListItem, ListItemText } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { Link, NavLink, useRouteMatch, Switch, Route } from 'react-router-dom'
import { RestApi } from '../../../../utils/apirequester';
import Cesium3DTilesetAdd from '../cesium3dtileset_add/Cesium3DTilesetAdd';
import Cesium3DTilesetEdit from '../cesium3dtilset_edit/Cesium3DTilesetEdit';
import { DataCesium3DTileset } from '../../../../utils/models';
import './Cesium3DTilesetList.scss';

export default function Cesium3DTilesetList() {
    const [cesium3DTilsetList, setCesium3DTilsetList] = useState<DataCesium3DTileset[]>([]);
    const refreshCesium3DTilesets = () => {
        RestApi.get<DataCesium3DTileset[]>('/api/v1/cesium3dtileset')
            .then(res => {
                setCesium3DTilsetList(res);
            })
    }
    useEffect(() => {
        refreshCesium3DTilesets();
    }, []);

    const { path, url } = useRouteMatch();
    return (
        <div className="Cesium3DTilesetList">
            <div className="left">
                <Link to={`${url}/add`}>
                    <Button> Cesium3DTileset Oluştur </Button>
                </Link>
                <List>
                    {cesium3DTilsetList.map(cesium3dtileset =>
                        <NavLink
                            to={`${path}/${cesium3dtileset.id}`}
                            activeStyle={{
                                fontWeight: "bold",
                                color: "red"
                            }}
                            key={cesium3dtileset.id}
                        >
                            <ListItem button>
                                <ListItemText primary={cesium3dtileset.name} />
                            </ListItem>
                        </NavLink>
                    )}
                </List>
            </div>
            <div className="right">
                <Card style={{padding: '16px'}}>
                    <Switch>
                        <Route exact path={path}>
                            Sol Menüden Cesium3DTileset Seçiniz
                            veya Cesium3DTileset ekleyiniz
                        </Route>
                        <Route exact path={`${path}/add`}>
                            <Cesium3DTilesetAdd onComplete={refreshCesium3DTilesets}/>
                        </Route>
                        <Route path={`${path}/:id`}>
                            <Cesium3DTilesetEdit reloadCallback={refreshCesium3DTilesets}/>
                        </Route>
                    </Switch>
                </Card>
            </div>
        </div>
    )
}
