import { useFormik } from 'formik';
import './Cesium3DTilesetAdd.scss';
import * as Yup from 'yup';
import { Button, FormControl, TextField } from '@material-ui/core';
import { RestApi } from '../../../../utils/apirequester';
import { DataCesium3DTileset } from '../../../../utils/models';
import { useHistory } from 'react-router';
interface Props {
    onComplete: (data: DataCesium3DTileset) => void
}
const Cesium3DTilesetAddSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Çok Kısa')
        .max(50, 'Çok Uzun')
        .required('Zorunlu')
})
export default function Cesium3DTilesetAdd(props: Props) {
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Cesium3DTilesetAddSchema,
        onSubmit: (values) => {
            RestApi.post<DataCesium3DTileset>('/api/v1/cesium3dtileset', values)
                .then(res => {
                    console.log('res', res);
                    props.onComplete(res);
                    history.push('/dashboard/cesium3dtileset');
                });
        }
    })
    return (
        <div className="Cesium3DTilesetAdd">
            <form onSubmit={formik.handleSubmit}>
                <FormControl>
                    <TextField
                        id="name"
                        label="Cesium3DTileset Adı"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={formik.touched.name && typeof formik.errors.name === 'string'}
                    />
                </FormControl>

                <Button type="submit">Cesium3DTileset Oluştur</Button>
            </form>
        </div>
    )
}
