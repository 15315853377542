import React, { useState, PropsWithChildren } from 'react'
import {
    ListItem,
    IconButton,
    Switch,
    Checkbox,
    FormControlLabel,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ColorPicker from '../../../../../../components/colorpicker/ColorPicker';


import { ITilesetExtended } from '../../../../../../utils/models'
import { actionProjectEditUpdateTileset, actionProjectTilesetZoom } from '../../../../../../redux/actions/project_edit';

interface Props {
    tileset: ITilesetExtended;
    onSaveConfirm: (t: ITilesetExtended) => void;
}
export default function ProjectTilesetSettingsItem(props: PropsWithChildren<Props>) {
    const dispatch = useDispatch();
    const [saveDialog, setSaveDialog] = useState(false);

    function onColorChange(value: string | null) {
        dispatch(actionProjectEditUpdateTileset(tileset.id, {
            style: value
        }))
    }
    function onZoom() {
        dispatch(actionProjectTilesetZoom(tileset.id, tileset.zoomTilesetTriggerValue + 1));
    }
    function onChange(event: any, key = 'value') {
        let body = null;
        if (event.target.type === 'number') {
            body = {
                [event.target.name]: +event.target[key]
            };
        } else {
            body = {
                [event.target.name]: event.target[key]
            }
        }
        dispatch(actionProjectEditUpdateTileset(tileset.id, body))
    }

    const tileset = props.tileset;
    return (
        <div className="ProjectTilesetSettingsItem">
            <ListItem className="item">
                <div className="row">
                    <TextField
                        name="name"
                        label="Tileset Adı"
                        type="text"
                        value={tileset.name}
                    onChange={onChange}
                    />
                    <Switch name="visible"
                        checked={tileset.visible}
                        onChange={(e) => onChange(e, 'checked')}
                    />
                </div>
                <span>
                    <IconButton onClick={() => onZoom()}>
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton aria-label="save" onClick={() => setSaveDialog(true)}>
                        <SaveIcon />
                    </IconButton>
                </span>
                <span className="config-container">
                    <TextField
                        name="offset_x"
                        label="Offset X"
                        type="number"
                        value={tileset.offset_x}
                        onChange={onChange}
                        inputProps={{ step: 0.000001 }}
                    />
                    <TextField
                        name="offset_y"
                        label="Offset Y"
                        type="number"
                        value={tileset.offset_y}
                        onChange={onChange}
                        inputProps={{ step: 0.000001 }}
                    />
                    <TextField
                        name="offset_z"
                        label="Offset Z"
                        type="number"
                        value={tileset.offset_z}
                        onChange={onChange}
                        inputProps={{ step: 0.1 }}
                    />
                    <TextField
                        name="maximum_screen_space_error"
                        label="Screen Space Error"
                        type="number"
                        value={tileset.maximum_screen_space_error}
                        onChange={onChange}
                        inputProps={{ min: 0, step: 0.1 }}
                    />
                    <ColorPicker
                        value={tileset.style}
                        onChange={onColorChange}
                    />
                    <br />
                    <Tooltip title="İki taraflı render olur, büyük veride performans sorunu olur">
                        <FormControlLabel
                            name="double_side_render"
                            value="start"
                            control={<Checkbox
                                color="secondary"
                                checked={tileset.double_side_render}
                                onChange={(e) => onChange(e, 'checked')}
                            />}
                            label="Double Side Render"
                            labelPlacement="start"
                        />
                    </Tooltip>

                </span>
            </ListItem>
            <Dialog open={saveDialog}
                onClose={() => setSaveDialog(false)}
            >
                <DialogTitle id="responsive-dialog-title">Tileset Güncelleme</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tileset bilgileri güncellenecek emin misiniz ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setSaveDialog(false)}>
                        İptal
                    </Button>
                    <Button color="primary" onClick={() => { setSaveDialog(false); props.onSaveConfirm(tileset) }}>
                        Onayla
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
