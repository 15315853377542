import React, { PropsWithChildren } from 'react'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import Prompt from '../../../../../components/prompt/Prompt';
import DeleteIcon from '@material-ui/icons/Delete';
import { convertFile2FileSystemResult, UploaderContext } from '../../uploader/Uploader';

import { RestApi } from '../../../../../utils/apirequester';


import {
    Button, Checkbox, FormControl, TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Input,
} from '@material-ui/core'

import './TerrainEdit.scss'
import { DataStatusTerrain, DataTerrain } from '../../../../../utils/models';


const TerrainEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Çok Kısa')
        .max(50, 'Çok Uzun')
        .required('Zorunlu')
})

function getProcessState(state: DataStatusTerrain['state']) {
    if (state === 'failure') {
        return 'HATA VERDI'
    }
    if (state === 'notstarted') {
        return 'BAŞLAMADI'
    }
    if (state === 'proggress') {
        return 'DEVAM EDIYOR'
    }
    if (state === 'success') {
        return 'HAZIR'
    }
}


interface Terrain {
    terrain: DataTerrain,
    process: DataStatusTerrain,
}

interface Props {
    reloadList: () => void;
}
export default function TerrainEdit(props: PropsWithChildren<Props>) {

    const uploaderContext = useContext(UploaderContext);
    const { id } = useParams<any>();
    const history = useHistory();
    const [terrain, setTerrain] = useState<Terrain | null>(null)

    const [deleteForm, setDeleteForm] = useState(false);
    const [deleteGeotifDataForm, setDeleteGeotifDataForm] = useState(false);
    const [deleteTerrainDataForm, setDeleteTerrainDataForm] = useState(false);
    const [logsForm, setLogsForm] = useState(false);
    const [processLogs, setProcessLogs] = useState('');

    useEffect(() => { reloadData(); }, [id]);

    const formik = useFormik({
        initialValues: { name: '' },
        validationSchema: TerrainEditSchema,
        onSubmit: (values) => {
            RestApi.put<DataTerrain>('/api/v1/terrain/' + id, values)
                .then(res => {
                    reloadData();
                    // props.reloadCallback();
                });
        }
    });

    async function reloadLogs() {
        // setProcessLogs('');
        const log = await RestApi.getRaw(`/api/v1/terrainprocess/${id}/log`).then(a => a.text());
        setProcessLogs(log);
    }
    async function reloadData() {
        try {
            const terrain = await RestApi.get<DataTerrain>('/api/v1/terrain/' + id)
            const process = await RestApi.get<DataStatusTerrain>('/api/v1/terrainprocess/' + id)
            setTerrain({ terrain, process });
            formik.setFieldValue('name', terrain.name);

        } catch (error) {
            console.log('TERRAIN NOT FOUND');
        }
    }

    async function onUploadStart(e: any) {
        try {
            const files = e.target.files as FileList;
            if (files.length !== 1) {
                return;
            }
            const file = files.item(0)!;
            uploaderContext({
                tileset_id: id,
                uploadPath: `/api/v1/terrain/${id}/uploadfile`,
                files: [convertFile2FileSystemResult(file, `${id}.tif`)],
                infoCallback: reloadData,
                title: terrain?.terrain.name || 'Arazi Modeli',
            });
        } catch (error) {
            console.log('ERROR', error);
            alert(error.message);
        }
    }

    async function deleteGeotifData() {
        await RestApi.delete(`/api/v1/terrainprocess/${id}/geotif`)
        reloadData()
    }
    async function deleteTerrainData() {
        await RestApi.delete(`/api/v1/terrainprocess/${id}/terrain`)
        reloadData()
    }
    async function showLogs() {
        setLogsForm(true);
        reloadLogs();
    }
    async function deleteTerrain() {
        RestApi.delete(`/api/v1/terrain/${id}`)
            .then(res => {
                // reloadData();
                history.push('/dashboard/terrain');
                props.reloadList();
            })
    }

    const processFormik = useFormik({
        initialValues: {
            min: 0,
            max: 16,
            verbose: false,
        },
        onSubmit: function ({ min, max, verbose }) {
            const params = `min=${min}&max=${max}&verbose=${verbose}`;
            RestApi.get(`/api/v1/terrainprocess/${id}/start?${params}`)
                .then(res => {
                    reloadData()
                })
        }
    })
    if (!terrain) {
        return null;
    }
    return (
        <div className="TerrainEdit">
            <div className="header">
                <h3>{terrain.terrain.name}</h3>
                <Button onClick={() => setDeleteForm(true)}><DeleteIcon /></Button>
            </div>
            <fieldset>

                <legend>Geotif</legend>
                <div className="state">
                    <div className="row">
                        <span>Geotif var mı </span>
                        {<Checkbox disabled checked={terrain.process.has_geotif} />}
                    </div>
                </div>
                <div className="data">
                    <input
                        id="terrain-edit-geotiff-upload"
                        onChange={onUploadStart}
                        type="file"
                        style={{ display: 'none' }} />
                    <label htmlFor="terrain-edit-geotiff-upload" className="input-file-label">
                        <Button
                            component="span"
                            color="primary"
                            variant="contained">4326 Geotif Yükle</Button>

                    </label>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => setDeleteGeotifDataForm(true)}>Geotif Sil
                    </Button>


                </div>
            </fieldset>
            <br />
            <fieldset>
                <legend>Process</legend>
                <div className="row">
                    <span>Process başlayabilir mi </span>
                    {<Checkbox disabled checked={terrain.process.can_start} />}
                </div>
                <div className="row">
                    <span>Process durumu: </span>
                    <i>{getProcessState(terrain.process.state)}</i>
                </div>

                <br />
                <form onSubmit={processFormik.handleSubmit} className="process-form">
                    <Input type="number" placeholder="min level"
                        name="min"
                        value={processFormik.values.min}
                        onChange={processFormik.handleChange}
                        inputProps={{ min: 0, max: 5 }}
                        disabled={!terrain.process.can_start}
                    />
                    <Input type="number" placeholder="max level"
                        name="max"
                        value={processFormik.values.max}
                        onChange={processFormik.handleChange}
                        inputProps={{ max: 18, min: 5 }}
                        disabled={!terrain.process.can_start}
                    />
                    <span>
                        Verbose Log
                        <Checkbox
                            name="verbose"
                            onChange={processFormik.handleChange}
                            checked={processFormik.values.verbose}
                            disabled={!terrain.process.can_start} />
                    </span>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!terrain.process.can_start}
                        type="submit">Process Başlat</Button>
                </form>
                
                <div className="data">
                    <Button
                        variant="contained"
                        onClick={showLogs}>Process Logları</Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteTerrainDataForm(true)}>Process sonucunu, logları ve verilerini sil</Button>

                </div>

            </fieldset>



            <form onSubmit={formik.handleSubmit}>
                <h3>Güncelleme</h3>
                <FormControl>
                    <TextField
                        id="name"
                        label="Terrain Adı"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={formik.touched.name && typeof formik.errors.name === 'string'}
                    />
                </FormControl>

                <Button type="submit">Güncelle</Button>
            </form>

            <Prompt
                open={deleteTerrainDataForm}
                callback={(res) => {
                    setDeleteTerrainDataForm(false);
                    if (res) {
                        deleteTerrainData();
                        return;
                    }
                }}
                title="Terrain (Arazi) Verileri Siliniyor"
                question="Dikkat Cesium'un Terrain (Arazi) verileri siliniyor. Silindiği takdirde tekrardan processi başlatmanız gerekir."
                agreeText="Sil"
                disAgreeText="İptal"
            />
            <Prompt
                open={deleteGeotifDataForm}
                callback={(res) => {
                    setDeleteGeotifDataForm(false);
                    if (res) {
                        deleteGeotifData();
                        return;
                    }
                }}
                title="Geotif Verisi Siliniyor"
                question="Silindiği takdirde process başlatmak isterseniz tekrardan geotif yüklemeniz gerekir"
                agreeText="Sil"
                disAgreeText="İptal"
            />
            <Prompt
                open={deleteForm}
                callback={(res) => {
                    setDeleteForm(false);
                    if (res) {
                        deleteTerrain();
                        return;
                    }
                }}
                title="Terrain Siliniyor"
                question="Terrain'e ait tüm veriler silinecektir. (Geotif, Terrain) bir projede kullanılıyor ise çalışmaz hale gelecektir."
                agreeText="Sil"
                disAgreeText="İptal"
            />
            <Dialog
                open={logsForm}
                maxWidth={'lg'}
                onClose={() => {
                    setLogsForm(false);
                    reloadData();
                }}
            >
                <DialogTitle>Loglar</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ whiteSpace: 'break-spaces' }}>
                        {/* <div style={{ whiteSpace: 'break-spaces' }}> */}
                        {processLogs}
                        {/* </div> */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions><Button onClick={reloadLogs}>Yenile</Button></DialogActions>
            </Dialog>

        </div>
    )
}
