import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux';

import { ReduxState } from '../../../../../redux/store';
import { RestApi } from '../../../../../utils/apirequester';
import { DataGeojsonProject } from '../../../../../utils/models';
import ProjectEditGeojsonSettingsItem from './geojson_settings_item/ProjectEditGeojsonSettingsItem';

import './ProjectEditGeojsonSettings.scss';

interface Props {
    viewer: Cesium.Viewer | null;
}

export default function ProjectEditGeojsonSettings(props: PropsWithChildren<Props>) {
    const geojsonList = useSelector<ReduxState, DataGeojsonProject[]>((state => state.projectEdit.geojsonList))

    function onSaveConfirm(g: DataGeojsonProject) {
        RestApi.put(`/api/v1/geojsonproject/${g.id}`, g).then(res => {
            console.log('udpated', res);
        })
    }

    return (
        <div className="ProjectEditGeojsonSettings">

            {geojsonList.map(g =>
                <ProjectEditGeojsonSettingsItem key={g.id}
                    geojson={g}
                    onSaveConfirm={onSaveConfirm}
                />
            )}
        </div>
    )
}
