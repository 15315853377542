import React, { PropsWithChildren, useState } from 'react'

import { Button, Card, CardContent, CardActions } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';

import { RestApi } from '../../../../../utils/apirequester'
import { Link } from 'react-router-dom';
import { DataGeojsonProject } from '../../../../../utils/models';
import { getStateElement } from '../../../../../utils/util';
import Prompt from '../../../../../components/prompt/Prompt';

import './ProjectGeojsonItem.scss';


interface Props {
    refreshGeojsons: () => void;
    geojsonProject: DataGeojsonProject
}
export default function ProjectGeojsonItem(props: PropsWithChildren<Props>) {

    const stateItem = getStateElement(props.geojsonProject.Geojson.state);

    const [deleteDialogOpenState, setDeleteDialogOpenState] = useState(false);

    function deleteResult(result: boolean) {
        setDeleteDialogOpenState(false);
        if (!result) {
            return;
        }
        RestApi.delete('/api/v1/geojsonproject/' + props.geojsonProject.id)
            .then(res => {
                props.refreshGeojsons();
            });

    }

    return (
        <>
            <div className="ProjectGeojsonItem">
                <Card>
                    <CardContent>
                        <div className="header">
                            <h3>{props.geojsonProject.name}</h3>
                            <Button onClick={() => setDeleteDialogOpenState(true)}>
                                <DeleteIcon />
                            </Button>
                        </div>
                        <Link to={`/dashboard/geojson/${props.geojsonProject.Geojson.id}`}>
                            Geojson: ({props.geojsonProject.Geojson.name})
                        </Link>
                    </CardContent>
                    <CardActions>
                        {stateItem}
                    </CardActions>
                </Card>
            </div>
            <Prompt
                open={deleteDialogOpenState}
                callback={deleteResult}
                title="Geojson Sil"
                question="Dikkat Geojson siliniyor, bu geojson'a  verileri de silinecektir"
                agreeText="Sil"
                disAgreeText="İptal"
            />
        </>
    )
}
