import React, { useContext, useEffect } from 'react'

import { CesiumViewerContext } from '../cesiumviewer/CesiumViewer'

interface Props {
    shadow: boolean;
}
export default function Shadow(props: Props) {
    const viewer = useContext(CesiumViewerContext)

    useEffect(() => {
        if(!viewer) {
            return;
        }
        viewer.shadows = props.shadow
    }, [props.shadow])
    return null;
}
