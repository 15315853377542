import { Tooltip } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ErrorIcon from '@material-ui/icons/Error';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { DataCesium3DTileset } from './models';

export function getStateElement(state: DataCesium3DTileset['state']) {
    if (state === 'inprogress') {
        return <Tooltip title="Test Devam Ediyor">
            <CircularProgress size={16} />
        </Tooltip>
    }

    if (state === 'missingfile') {
        return <Tooltip title="Eksik Dosya">
            <ErrorIcon />
        </Tooltip>
    }
    if (state === 'nodata') {
        return <Tooltip title="Henüz Yükleme yapılmamış">
            <FolderOpenIcon />
        </Tooltip>
    }
    if (state === 'verified') {
        return <Tooltip title="Dosyalar Onaylandı">
            <VerifiedUserIcon />
        </Tooltip>
    }
}
