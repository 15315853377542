import { Button, List, ListItem, ListItemText } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { RestApi } from '../../../../utils/apirequester'
import { Switch, Route, useRouteMatch, Link, NavLink } from 'react-router-dom'
import ProjectAdd from '../project_add/ProjectAdd';
import './ProjectList.scss';
import ProjectDetail from '../project_detail/ProjectDetail';
interface DataProject {
    id: number;
    name: string;
}
export default function ProjectList() {
    const [projects, setProjects] = useState<DataProject[]>([]);
    const refreshProjects = () => {
        RestApi.get<DataProject[]>('/api/v1/project')
            .then(res => {
                setProjects(res);
            })
    }
    useEffect(() => {
        refreshProjects();
    }, []);
    const { path, url } = useRouteMatch();
    return (
        <div className="ProjectList">
            <div className="left">
                <Link to={`${url}/add`}>
                    <Button>Proje Oluştur</Button>
                </Link>
                <List>
                    {projects.map(p =>
                        <NavLink
                            to={`${path}/${p.id}`}
                            activeStyle={{
                                fontWeight: "bold",
                                color: "red"
                            }}
                            key={p.id}
                        >
                            <ListItem button>
                                <ListItemText primary={p.name} />
                            </ListItem>
                        </NavLink>
                    )}
                </List>
            </div>
            <div className="right">
                <Switch>
                    <Route exact path={path}>
                        Sol menüden
                        Proje Seçiniz
                        veya Proje ekleyin
                    </Route>
                    <Route exact path={`${path}/add`}>
                        <ProjectAdd refreshProjects={refreshProjects} />
                    </Route>
                    <Route path={`${path}/:project_id`}>
                        <ProjectDetail refreshProjects={refreshProjects}/>
                    </Route>
                </Switch>
            </div>
        </div>
    )
}
