import React, { useEffect, useState } from 'react'
import { Button, Card } from '@material-ui/core'
import { RestApi } from '../../../../utils/apirequester'
import { DataProject } from '../project_detail/ProjectDetail'
import CancelIcon from '@material-ui/icons/Cancel';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';
import TilesetAdd from '../tileset_add/TilesetAdd';
import Tileset from '../tileset/Tileset';
import './ProjectTileset.scss';
import { DataTileset } from '../../../../utils/models';

interface Props {
    project: DataProject
}
export default function ProjectTileset(props: Props) {

    const project = props.project;
    const [tilesets, setTilesets] = useState<DataTileset[]>([]);
    const { path, url } = useRouteMatch();

    useEffect(() => {
        refreshTilesets();
    }, [project.id]);
    return (
        <Card>
            <div className="ProjectTileset">
                <Switch>
                    <Route exact path={path}>
                        <div className="header">
                            <h2>Tileset Listesi</h2>
                            <Link to={`${url}/addtileset`}><Button>Tileset Ekle</Button></Link>
                        </div>

                        <div className="list">
                            {tilesets.map(t => <Tileset
                                refreshTilesets={refreshTilesets}
                                key={t.id}
                                tileset={t} />
                            )}
                        </div>
                    </Route>
                    <Route path={`${url}/addtileset`}>
                        <div className="header">
                            <h2>Tileset Ekle</h2>
                            <Link to={url}><Button><CancelIcon /></Button></Link>
                        </div>
                        <TilesetAdd refreshTileset={refreshTilesets} project={project} />
                    </Route>
                    {/* <Route
                        path={`${url}/edit`}
                        render={(routeProps) => (
                            <ProjectEdit
                                project_id={project.id}
                                {...routeProps}
                                parentRoute={url}
                            />
                        )}
                    /> */}
                </Switch>
            </div>
        </Card>
    );


    function refreshTilesets() {
        setTilesets([]);
        RestApi.get<DataTileset[]>(`/api/v1/project/${project.id}/tileset`)
            .then((res) => {
                setTilesets(res);
            });
    }
}
