import React from 'react'
import {
    Checkbox
} from '@material-ui/core';
import './ColorPicker.scss';
import Slider from '@material-ui/core/Slider';


function colorParser(val: string | null): [string, number] {
    if (val === null) {
        return ['#ffffff', 1];
    }
    const hexColor = val.substring(0, 7);
    const alphaColor = val.substring(7, 9);
    const alpha = (Number as any)(`0x${alphaColor}`, 16) / 255 as number;
    if(isNaN(alpha)) {
        return ['#ffffff', 1];
    }
    return [hexColor, alpha];
}

function alphaToHex(n: number): string {
    const hex = +(n * 255).toFixed(0);
    // console.log('hex', hex);

    const val = hex.toString(16);
    // console.log('val', val);
    return val;
}

interface Props {
    value: string | null;
    onChange: (value: string | null) => void;
}
export default function ColorPicker(props: Props) {
    const isColorAvaible = props.value !== null;
    // console.log('props.value', props.value);

    const [color, alpha] = colorParser(props.value);

    function onCheckboxChange(checked: boolean) {
        if(checked) {
            props.onChange(color + alphaToHex(alpha));
        } else {
            props.onChange(null);
        }
    }
    function onColorChange(val: string) {
        props.onChange(val + alphaToHex(alpha));
    }
    function onSliderChange(val: number) {
        // console.log(color + alphaToHex(val));
        props.onChange(color + alphaToHex(val));
    }
    return (
        <div className="ColorPicker">
            <div className="ColorPicker_row">
                <span>Renklendirme</span>
                <Checkbox 
                    onChange={e => onCheckboxChange(e.target.checked)}
                    checked={isColorAvaible}
                />
            </div>
            {isColorAvaible ?
                <div className="ColorPicker_row">
                    <input type="color"
                        disabled={!isColorAvaible}
                        onChange={e => onColorChange(e.target.value)}
                        value={color} />
                    <span className="empty"></span>
                    <Slider
                        onChange={(e, newvalue) => onSliderChange(newvalue as any)}
                        value={alpha}
                        step={0.1}
                        min={0.1}
                        max={1}
                    />
                </div>
                : null
            }
        </div>
    )
}
