import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@material-ui/core'

interface Props {
    title: string;
    question: string;
    open: boolean;
    callback: (result: boolean) => void;
    agreeText?: string;
    disAgreeText?: string;
}
export default function Prompt(props: Props) {
    return (
        <Dialog
            open={props.open}
            onClose={() => props.callback(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.question}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.callback(false)} color="primary">
                    {props.disAgreeText || 'Disagree'}
                </Button>
                <Button onClick={() => props.callback(true)} color="primary" autoFocus>
                    {props.agreeText || 'Agree'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
