import React, { useState, useEffect } from 'react'
import { Button, Card, List, ListItem, ListItemText } from '@material-ui/core';


import { Link, NavLink, useRouteMatch, Switch, Route } from 'react-router-dom'

import './Terrain.scss'
import { RestApi } from '../../../../utils/apirequester';
import TerrainAdd from './terrain_add/TerrainAdd';
import TerrainEdit from './terrain_edit/TerrainEdit';
export default function Terrain() {


    const { path, url } = useRouteMatch();

    const [terrainList, setTerrainList] = useState<any[]>([])

    function refreshTerrainList() {
        RestApi.get<any[]>('/api/v1/terrain').then(setTerrainList);

    }
    useEffect(() => {
        refreshTerrainList();
    }, []);


    return (
        <div className="Terrain">

            <div className="left">
                <Link to={`${url}/add`}>
                    <Button> Terrain Oluştur </Button>
                </Link>
                <List>
                    {terrainList.map(t =>
                        <NavLink
                            to={`${path}/${t.id}`}
                            activeStyle={{
                                fontWeight: "bold",
                                color: "red"
                            }}
                            key={t.id}
                        >
                            <ListItem button>
                                <ListItemText primary={t.name} />
                            </ListItem>
                        </NavLink>
                    )}

                </List>
            </div>

            <div className="right">
                <Card style={{ padding: '16px' }}>
                    <Switch>
                        <Route exact path={path}>
                            Sol Menüden Geojson Seçiniz
                            veya Geojson ekleyiniz

                        </Route>
                        <Route exact path={`${path}/add`}>
                            <TerrainAdd onComplete={refreshTerrainList} />
                        </Route>
                        <Route path={`${path}/:id`}>
                            <TerrainEdit reloadList={refreshTerrainList} />

                        </Route>
                    </Switch>
                </Card>

            </div>

        </div>
    )
}
