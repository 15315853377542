import { Card, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import { DataProject } from './ProjectDetail'
import DeleteIcon from '@material-ui/icons/Delete';
import './ProjectDetailComponent.scss';
import { Button } from '@material-ui/core';
import Prompt from '../../../../components/prompt/Prompt';
import PublicIcon from '@material-ui/icons/Public';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';
interface Props {
    project: DataProject;
    deleteProject: () => void;
    viewProject: () => void;
}

export default function ProjectDetailComponent(props: Props) {
    const {
        project,
    } = props;
    const [deleteDialogOpenState, setDeleteDialogOpenState] = useState(false);
    return (
        <Card>
            <div className="ProjectDetailComponent">
                <h2>Proje: {project.name}</h2>
                <div>
                    <Tooltip title="Önizle">
                        <Link to={`/iframe/${props.project.id}`}>
                            <Button>
                                <PublicIcon />
                            </Button>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Düzenle">
                        <Button onClick={props.viewProject}>
                            <SettingsIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Sil">
                        <Button onClick={() => setDeleteDialogOpenState(true)}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </div>
                <Prompt
                    open={deleteDialogOpenState}
                    callback={deleteResult}
                    title="Proje Sil"
                    question="Dikkat Proje siliniyor, bu projeye ait Tilesetler ve Point (sanal tur) verileri de silinecektir"
                    agreeText="Sil"
                    disAgreeText="İptal"
                />
            </div>
        </Card>
    )

    function deleteResult(del: boolean) {
        setDeleteDialogOpenState(false);
        if(!del) {
            return;
        }
        props.deleteProject();
    }
}
