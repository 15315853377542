import React, { useEffect, useState } from 'react'
import { useHistory, useParams, Switch, Route, useRouteMatch  } from 'react-router-dom';

import { RestApi } from '../../../../utils/apirequester';
import ProjectEdit from '../project_edit/ProjectEdit';
import ProjectGeojson from '../project_geojson/ProjectGeojson';
import ProjectTileset from '../project_tileset/ProjectTileset';
import ProjectDetailComponent from './ProjectDetailComponent';
import { Dialog } from '@material-ui/core'

interface Props {
    refreshProjects: () => void;
}
export interface DataProject {
    id: number;
    name: string;
}
export default function ProjectDetail(props: Props) {
    const { project_id } = useParams<any>();
    const history = useHistory();
    
    const [project, setProject] = useState<DataProject | null>(null);
    useEffect(() => {
        setProject(null);
        RestApi.get<DataProject>('/api/v1/project/' + project_id)
            .then(res => setProject(res))

    }, [project_id]);
    
    const { url, path } = useRouteMatch();
    if (project === null) {
        return <h2>Yükleniyor...</h2>
    }
    return (
        <>
            <ProjectDetailComponent
                project={project}
                deleteProject={deleteProject}
                viewProject={viewProject}
        
            />
            <br/>
            <ProjectTileset project={project}/>
            <br/>
            <ProjectGeojson project={project}/>

            <Switch>
                <Route path={`${path}/edit`}>
                    <Dialog open fullScreen>
                        <ProjectEdit />
                    </Dialog>
                </Route>
            </Switch>
        </>
    )
    function viewProject() {
        history.push(`${url}/edit`);
    }
    function deleteProject() {
        RestApi.delete('/api/v1/project/' + project?.id)
        .then(res => {
            props.refreshProjects();
            history.push('/dashboard/project')
        })
    }
}
