import React, { PropsWithChildren } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Tooltip,
    TextField,
    Select,
    MenuItem,
} from '@material-ui/core'

import VisibilityIcon from '@material-ui/icons/Visibility';
import SaveIcon from '@material-ui/icons/Save';

import { ReduxState } from '../../../../../redux/store';
import { IProject, DataTerrain } from '../../../../../utils/models';
import { RestApi } from '../../../../../utils/apirequester';
import { actionProjectEditUpdateProject } from '../../../../../redux/actions/project_edit';
import './ProjectEditSettings.scss';

interface Props {
    viewer: Cesium.Viewer | null;
}
export default function ProjectEditSettings(props: PropsWithChildren<Props>) {
    const project = useSelector<ReduxState, IProject | undefined>((state => state.projectEdit.project))
    const terrainList = useSelector<ReduxState, DataTerrain[]>((state => state.projectEdit.terrainList))
    const dispatch = useDispatch();

    if (!project) {
        return null;
    }
    const terrainValue = project.TerrainId || 0;
    return (
        <div className="ProjectEditSettings">

            <div className="project-container">
                <TextField
                    type="text"
                    value={project.name}
                    onChange={(e) => {
                        dispatch(actionProjectEditUpdateProject({ name: e.target.value }))
                    }}
                />

                <Tooltip title="Gölgeleri aktif eder. Perfomans kaybına sebep olur">
                    <FormControlLabel
                        name="shadow"
                        value="start"
                        control={<Checkbox
                            color="secondary"
                            checked={project.shadow}
                            onChange={(e) => {
                                dispatch(actionProjectEditUpdateProject({ shadow: !project.shadow }))
                            }}
                        />}
                        label="Gölge"
                        labelPlacement="start"
                    />
                </Tooltip>
                <Tooltip title="iFrame Apisinde katman ayarlarını açmasına izin verir">
                    <FormControlLabel
                        name="iframe_panel"
                        value="start"
                        control={<Checkbox
                            color="secondary"
                            checked={project.iframe_panel}
                            onChange={(e) => {
                                dispatch(actionProjectEditUpdateProject({
                                    iframe_panel: !project.iframe_panel
                                }))
                            }}
                        />}
                        label="iFrame Panel"
                        labelPlacement="start"
                    />
                </Tooltip>
                <Tooltip title="Kamera konumunu açılış konumu olarak ayarla">
                    <Button onClick={() => {
                        if (!props.viewer) {
                            return;
                        }
                        const cam = props.viewer.camera;
                        const dir = cam.direction;
                        const up = cam.up;
                        const pos = Cesium.Cartographic.fromCartesian(cam.position);
                        const body = {
                            camera_direction: { coordinates: [dir.x, dir.y, dir.z] },
                            camera_up: { coordinates: [up.x, up.y, up.z] },
                            camera_position: {
                                coordinates: [
                                    pos.longitude * 180 / Math.PI,
                                    pos.latitude * 180 / Math.PI,
                                    pos.height
                                ]
                            },
                        }
                        dispatch(actionProjectEditUpdateProject(body))
                    }}> Kamerayı Ayarla <VisibilityIcon />
                    </Button>
                </Tooltip>

                <Select
                    name="TilesetId"
                    value={terrainValue}
                    onChange={(e) => {
                        console.log('ee', e.target.value);
                        dispatch(actionProjectEditUpdateProject({ TerrainId: e.target.value }))
                    }}
                >
                    <MenuItem value={0}>Arazi Modeli YOK</MenuItem>
                    {terrainList.map(terrain => <MenuItem
                        key={terrain.id}
                        value={terrain.id}
                    >{terrain.name}</MenuItem>)}
                </Select>


                <Tooltip title="Kaydet">
                    <Button onClick={() => {
                        const body = Object.assign({}, project, {
                            camera_direction: project.camera_direction.coordinates,
                            camera_up: project.camera_up.coordinates,
                            camera_position: project.camera_position.coordinates,
                        });
                        RestApi.put(`/api/v1/project/${project.id}`, body)
                            .then(res => {
                                console.log('RES', res);
                            })
                    }}> Güncelle <SaveIcon />
                    </Button>
                </Tooltip>
            </div>

        </div>
    )
}
