import { useEffect } from 'react'
import { useFormik } from 'formik'
import { TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core'
import { RestApi } from '../../../../utils/apirequester';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import './ProjectAdd.scss';

const ProjectSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    default_terrain_id: Yup.number(),
});

interface Props {
    refreshProjects: () => void
}
export default function ProjectAdd(props: Props) {
    useEffect(() => {
        RestApi.get<any[]>('/api/v1/terrain').then(res => {
        });
    }, [])
    const history = useHistory();
    // const location = useLocation();
    // const routeMatch = useRouteMatch();
    const formik = useFormik({
        initialValues: {
            name: '',
            default_terrain_id: -1,
        },
        validationSchema: ProjectSchema,
        onSubmit: _values => {
            const values = Object.assign({}, _values);
            if (values.default_terrain_id === -1) {
                delete (values as any).default_terrain_id;
            }
            RestApi.post('/api/v1/project', values).then((res) => {
                props.refreshProjects();
                history.goBack();
            })
        },

    });
    return (
        <form className="ProjectAdd" onSubmit={formik.handleSubmit}>
            <FormControl>
                <TextField
                    id="name"
                    label="Proje Adı"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.touched.name && typeof formik.errors.name === 'string'}
                />
            </FormControl>
            <FormControl>
                <InputLabel id="default_terrain_id-label">Arazi Modeli</InputLabel>
                <Select
                    labelId="default_terrain_id-label"
                    id="default_terrain_id"
                    value={formik.values.default_terrain_id}
                    onChange={formik.handleChange}
                >
                    <MenuItem value={-1}>Düz Arazi</MenuItem>
                </Select>
            </FormControl>

            <Button type="submit">Projeyi Oluştur</Button>
        </form>
    )
}
