import { useState } from 'react'
import { Button, Card, CardContent, CardActions } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import './Tileset.scss';
import Prompt from '../../../../components/prompt/Prompt';
import { RestApi } from '../../../../utils/apirequester';
import { DataTileset } from '../../../../utils/models';
import { getStateElement } from '../../../../utils/util';
import { Link } from 'react-router-dom';

interface Props {
    tileset: DataTileset;
    refreshTilesets: () => void;
}
export default function Tileset(props: Props) {

    const stateItem = getStateElement(props.tileset.Tileset.state);
    const [deleteDialogOpenState, setDeleteDialogOpenState] = useState(false);

    return (
        <>
            <div className="Tileset">
                <Card>
                    <CardContent>
                        <div className="header">
                            <h3>{props.tileset.name}</h3>
                            <Button onClick={() => setDeleteDialogOpenState(true)}>
                                <DeleteIcon />
                            </Button>
                        </div>
                        <Link to={`/dashboard/cesium3dtileset/${props.tileset.Tileset.id}`}>
                            Cesium3DTileset: ({props.tileset.Tileset.name})
                        </Link>
                    </CardContent>
                    <CardActions>
                        {stateItem}
                    </CardActions>
                </Card>
            </div>
            <Prompt
                open={deleteDialogOpenState}
                callback={deleteResult}
                title="Tileset Sil"
                question="Dikkat Tileset siliniyor, bu tileset'e  verileri de silinecektir"
                agreeText="Sil"
                disAgreeText="İptal"
            />
        </>
    );
    function deleteResult(result: boolean) {
        setDeleteDialogOpenState(false);
        if (!result) {
            return;
        }
        RestApi.delete('/api/v1/tileset/' + props.tileset.id)
            .then(res => {
                props.refreshTilesets();
            });

    }
}
